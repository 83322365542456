import React, {
  useState,
  useEffect,
  useRef,
  Component,
  ChangeEvent,
} from "react";
import {
  ActionButton,
  RetryButton,
  SendButton,
  SendOkButton,
} from "../ehglish/parts/Button";
import { Container, Input } from "@yamada-ui/react";
import { Checkbox, CheckboxGroup } from "@yamada-ui/react";
import { FileButton } from "@yamada-ui/react";
import GoogleMapReact from "google-map-react";
import { GoogleMapArea } from "../ehglish/parts/maps/maps";
//import { useSupabase } from '../utils/supabaseClient'
import { createClient } from "@supabase/supabase-js";
import { Database } from "../types/supabase";
import { useSupabase } from "../utils/supabaseClient";

// const supabase = createClient(
//   "https://gyekggzqmtuttbfsqpkt.supabase.co",
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd5ZWtnZ3pxbXR1dHRiZnNxcGt0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTM5NTkzNzQsImV4cCI6MjAyOTUzNTM3NH0.3E4KgJVkSPmxNjCoVn1--kCA5JkucyE8We57Rj8UhHg"
// );

interface MarkerPosition {
  lng: number;
  lat: number;
}

export const DonePage: React.FC<{ data1: number; data2: number }> = (props) => {
  useEffect(() => {
    isFirstRef.current = false;
    if ("geolocation" in navigator) {
      setAvailable(true);
    }
  }, []);

  //各種変数＝＝＝＝＝＝＝
  const [chk, setChk] = useState({
    eventName: "第1回 GGTR", //バイク
    locationName: "", //フェリー
    nickName: "", //SA・PA
    comment: "", //道の駅
    bike_flag: 0,
    ferry_flag: 0,
    sapa_flag: 0,
    waypoint_flag: 0,
    goalPoint: 0,
    totalPoint: 0,
    latitude: 0,
    longitude: 0,
    location_url: "",
  });

  // 個別に値を更新する関数
  const updateChk = (paramName: string, paramValue: any) => {
    setChk((prevChk) => ({
      ...prevChk, // 既存のstateを展開
      [paramName]: paramValue, // 指定されたパラメータを更新
    }));
  };

  const locationNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    updateChk("locationName", value);
  };

  const nickNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    updateChk("nickName", value);
  };

  const commentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    updateChk("comment", value);
  };

  const [count, setCount] = useState(0); // countをstateで管理

  const bike_flagChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const increment = isChecked ? 10 : -10; // チェックが入ったら+1、外れたら-1
    setCount((prevCount) => prevCount + increment);
    const { value } = e.target;
    updateChk("bike_flag", value);
  };

  const ferry_flagChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const increment = isChecked ? 5 : -5; // チェックが入ったら+1、外れたら-1
    setCount((prevCount) => prevCount + increment);
    const { value } = e.target;
    updateChk("ferry_flag", value);
  };

  const sapa_flagChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const increment = isChecked ? 1 : -1; // チェックが入ったら+1、外れたら-1
    setCount((prevCount) => prevCount + increment);
    const { value } = e.target;
    updateChk("sapa_flag", value);
  };

  const waypoint_flagChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const increment = isChecked ? 1 : -1; // チェックが入ったら+1、外れたら-1
    setCount((prevCount) => prevCount + increment);
    const { value } = e.target;
    updateChk("waypoint_flag", value);
  };

  const goalPointChange = (e: ChangeEvent<HTMLInputElement>) => {
    // const value = parseInt(e.target.value, 10); // 文字列を数値に変換
    // setCount((prevCount) => prevCount + value);
    const { value } = e.target;
    updateChk("goalPoint", value);
  };

  const totalPointChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateChk("totalPoint", count);
  };

  const latitudeChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateChk("latitude", props.data1);
  };

  const longitudeChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateChk("longitude", props.data2);
  };

  const location_urlChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateChk("location_url", googlemapUrlValue);
  };

  //イベント名
  const [eventValue, setEventValue] = useState<string>("第1回 GGTR");

  const { supabase } = useSupabase();

  const [isDoneSend, setDoneSend] = useState<boolean>(false);

  //地点URL
  const [googlemapUrlValue, setGooglemapValue] = useState<string>(
    "https://www.google.com/maps/search/?api=1&query=" +
      props.data1 +
      "," +
      props.data2
  );
  //const [records, setRecords] = useState<SupabaseType>();
  //type Tables<T extends keyof Database['public']['Tables']['geolocation_list']> = Database['public']['Tables']['geolocation_list'];
  //let comment: Tables<'comment'>;

  // const addPost = async () => {
  //   //e.preventDefault();
  //   try {
  //     // const { error } = await supabase.from("geolocation_list").insert(
  //     //   {
  //     //     bike_flag: chk.bike_flag,
  //     //     waypoint_flag: chk.waypoint_flag,
  //     //   },
  //     // );
  //     // if (error) throw error;

  //     // const { data, error } = await supabase.from("geolocation_list").select(`bike_flag`);
  //     // const { data, error } = await supabase.from("geolocation_list").insert([{ title: "", time: "" }])

  //     // await supabase.from('geolocation_list').insert({ comment: "aaa" });

  //     const { data: geolocation_list, error } = await supabase
  //     .from('geolocation_list')
  //     .select('*');

  //     // await supabase.from('geolocation_list')
  //     // .insert(
  //     //   {
  //     //     bike_flag: 0,
  //     //     comment: "",
  //     //   }).select()

  //     // .select();
  //     console.log(geolocation_list, error);
  //     alert(geolocation_list);
  //     //setRecords(geolocation_list);
  //     alert(geolocation_list);
  //   } catch (error) {
  //     alert("データの新規登録ができません");
  //   }
  // };

  const [data, setData] = useState<any[]>([]);
  const [newData, setNewData] = useState<string>("");
  // 初回レンダリング時にデータを取得する
  useEffect(() => {
    fetchData();
  }, []);

  // データの選択
  const fetchData = async () => {
    try {
      const { data, error } = await supabase
        .from("geolocation_list")
        .select("*");
      if (error) {
        throw error;
      }
      setData(data || []);
    } catch (error) {
      alert("Error fetching data:" + error);
    }
  };

  const newEntries = [
    {
      comment: "aa",
      bike_flag: 0,
    },
  ];
  // データの挿入
  const insertData = async () => {
    // alert("開始");
    try {
      // if (!newData) return;

      const { data, error } = await supabase
        .from("geolocation_list")
        .insert({
          event_tag: chk.eventName, //バイク
          location_name: chk.locationName, //フェリー
          name: chk.nickName, //SA・PA
          comment: chk.comment, //道の駅
          bike_flag: chk.bike_flag,
          ferry_flag: chk.ferry_flag,
          sapa_flag: chk.sapa_flag,
          waypoint_flag: chk.waypoint_flag,
          point: chk.goalPoint,
          // totalPoint: chk.totalPoint,
          latitude: props.data1,
          longitude: props.data2,
          location_url: googlemapUrlValue,
        })
        .select("*");
      //const { data, error } = await supabase.from('test').insert({test2: "222"}).select('*');
      // alert("1");
      if (error) {
        console.log("aaaa");
        // alert("1-2");
        alert(error.message);
        throw error;
      }
      alert("送信が完了しました！");
      setDoneSend(true);
      // alert("Inserted data:" + data[0]);
      // setNewData("");
      // データを再取得して画面を更新
      // fetchData();
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  const [position, setPosition] = useState<MarkerPosition>({
    lat: 36.0489434251856,
    lng: 139.3759852341116,
  });

  const [isAvailable, setAvailable] = useState<boolean>(false);
  const isFirstRef = useRef<boolean>(true);

  if (isFirstRef.current) return <div className="App">Loading...</div>;

  function reloadPage() {
    window.location.reload();
  }

  return (
    <div className="App">
      <div>
        <center>
          <GoogleMapArea
            initialPosition={{
              lat: props.data1,
              lng: props.data2,
            }}
          />

          {/* <p>
            緯度(latitude): {props.data1} 経度(longitude): {props.data2}
            緯度(latitude): {position.lat} 経度(longitude): {position.lng}
          </p> */}
        </center>
      </div>

      <Container size="xs" variant="with-border-dotted">
        <ul>
          <li>
            <p>
              イベント名
              <Input
                value={eventValue}
                isDisabled
                variant="flushed"
                placeholder="イベント名"
                size="sm"
                htmlSize={30}
                width="auto"
              />
            </p>
          </li>
          <li>
            <p>
              地点名称
              <Input
                onChange={locationNameChange}
                variant="flushed"
                placeholder="必須(例: 道の駅なめがわ)"
                size="sm"
                htmlSize={30}
                width="auto"
              />
            </p>
          </li>
          <li>
            <p>
              名前
              <Input
                onChange={nickNameChange}
                variant="flushed"
                placeholder="必須(例:いーぐりっしゅ)"
                size="sm"
                htmlSize={30}
                width="auto"
              />
            </p>
          </li>
          <li>
            <p>
              コメント
              <Input
                onChange={commentChange}
                variant="flushed"
                placeholder="コメント"
                size="sm"
                htmlSize={30}
                width="auto"
              />
            </p>
          </li>
          {/* <li>
            <p>
              画像
              <Input
                variant="flushed"
                placeholder="画像"
                size="sm"
                htmlSize={20}
                width="auto"
              />
            </p>
          </li> */}
        </ul>
        <ul>
          <li>
            <p>
              <input type="checkbox" onChange={bike_flagChange} value="1" />
              <label htmlFor="bike_flag">
                バイク(+10pt) ※ゴール地点でのみ有効
              </label>
            </p>
          </li>
          <li>
            <p>
              <input type="checkbox" onChange={ferry_flagChange} value="1" />
              <label htmlFor="ferry_flag">
                フェリー(+5pt) ※ゴール地点でのみ有効
              </label>
            </p>
          </li>
          <li>
            <p>
              <input type="checkbox" onChange={sapa_flagChange} value="1" />
              <label htmlFor="sapa_flag">SA・PA(+1pt)</label>
            </p>
          </li>
          <li>
            <p>
              <input type="checkbox" onChange={waypoint_flagChange} value="1" />
              <label htmlFor="waypoint_flag">道の駅(+1pt)</label>
            </p>
          </li>
          <li>
            <p>
              順位点(手入力)
              <Input
                onChange={goalPointChange}
                placeholder="参加人数-(順位-1)"
                size="xs"
                htmlSize={20}
                width="auto"
              />
            </p>
          </li>
        </ul>
        {/* <p>
          合計: <b>15</b> pt
        </p> */}
        {/* <ActionButton ButtonName={"送信する"}></ActionButton> */}
        <center>
          {isDoneSend ? (
            <SendOkButton ButtonName={"送信完了！"} />
          ) : (
            <SendButton onClick={insertData} ButtonName={"送信する"} />
            // <button onClick={insertData}>送信する</button>
          )}
        </center>

        {/* <button onClick={addPost}>send!!</button> */}
      </Container>
      {/* <p>eventName: {chk.eventName}</p>
      <p>locationName: {chk.locationName}</p>
      <p>nickName: {chk.nickName}</p>
      <p>waypoint: {chk.waypoint_flag}</p>
      <p>longitude: {props.data1}</p>
      <p>latitude: {props.data2}</p>
      <p>goalpoint: {chk.goalPoint}</p>
      <p>tensuu: {count} </p> */}
      <p>地点URL:</p>
      <p>
        <Input
          onChange={location_urlChange}
          value={googlemapUrlValue}
          size="sm"
          htmlSize={40}
          width="auto"
        />
      </p>
      {/* <button onClick={reloadPage}>現在地取得からやり直す</button> */}
      <RetryButton onClick={reloadPage} ButtonName="現在地取得からやり直す" />
      {/* <h1>Supabase Data</h1>
      <div>
        <input
          type="text"
          value={newData}
          onChange={(e) => setNewData(e.target.value)}
          placeholder="Enter new data"
        />
        <button onClick={insertData}>Insert Data</button>
        <br />
      </div> */}
      {/* <div>
        <h2>Data from Supabase:</h2>
        <ul>
          {data.map((item, index) => (
            <li key={index}>{item.column_name}</li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};
