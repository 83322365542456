import { createClient } from "@supabase/supabase-js";
import { Database } from "../types/supabase";


//  const supabaseUrl = process.env.REACT_APP_PUBLIC_SUPABASE_URL;
//  const supabaseAnonKey = process.env.REACT_APP_PUBLIC_SUPABASE_ANON_KEY;

 const supabaseUrl = "https://gyekggzqmtuttbfsqpkt.supabase.co"
 const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd5ZWtnZ3pxbXR1dHRiZnNxcGt0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTM5NTkzNzQsImV4cCI6MjAyOTUzNTM3NH0.3E4KgJVkSPmxNjCoVn1--kCA5JkucyE8We57Rj8UhHg"

export const useSupabase = () => {
    if (!supabaseUrl || !supabaseAnonKey) {
      throw new Error("Supbase環境変数の設定に問題がある可能性が高いです。");
    }
  
    // 環境変数や型ファイルを適用したクライアントを作成
    const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
      auth: {
        persistSession: false,
        autoRefreshToken: false,
      },
    });
  
    return { supabase };
  };