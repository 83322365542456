import React, {
  useState,
  useEffect,
  useRef,
  Component,
  ChangeEvent,
} from "react";
import '../App.css';
import { GetGpsButton } from "../ehglish/parts/Button";

interface CommitPageProps {
  onDataGenerated: (data1: any, data2: any) => void;
}

interface MarkerPosition {
  lng: number | null;
  lat: number | null;
}
// GeoLocationが使えない時のエラーメッセージ
const ErrorText: React.FC = () => (
  <p className="App-error-text">
    Geolocation
    APIが一時的に使えないようです。時間をおいてもう一度試してください…
  </p>
);

export const CommitPage: React.FC<CommitPageProps> = (
  props: CommitPageProps
) => {
  useEffect(() => {
    isFirstRef.current = false;
    if ("geolocation" in navigator) {
      setAvailable(true);
    }
  }, []);

  const [position, setPosition] = useState<MarkerPosition>({
    lat: 36.0489434251856,
    lng: 139.3759852341116,
  });
  const [isAvailable, setAvailable] = useState<boolean>(false);
  const isFirstRef = useRef<boolean>(true);

  if (isFirstRef.current) return <div className="App">Loading...</div>;

  //現在地の取得
  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      setPosition({ lat: latitude, lng: longitude });
      props.onDataGenerated(latitude, longitude);
    });
  };

  return (
    <div className="App">
      <div>
        <center>
          {!isAvailable && <ErrorText />}
          {isAvailable && (
            <div>
              {/* <button onClick={getCurrentPosition}>現在位置を取得する</button> */}
              <GetGpsButton onClick={getCurrentPosition} ButtonName="現在位置を取得する"/>
            </div>
          )}
          {/* <p>
            緯度(latitude): {position.lat} 経度(longitude): {position.lng}
          </p> */}
        </center>
      </div>
    </div>
  );
};
