import { Button } from "@yamada-ui/react";

interface ActionButtonProps {
  onClick?: () => void;
  ButtonName: string;
}

interface SendButtonProps {
  onClick?: () => void;
  ButtonName: string;
}

interface SendOkButtonProps {
  onClick?: () => void;
  ButtonName: string;
}

interface GetGpsButtonProps {
  onClick?: () => void;
  ButtonName: string;
}

interface RetryButtonProps {
    onClick?: () => void;
    ButtonName: string;
  }

export const ActionButton = (props: ActionButtonProps) => {
  const { onClick, ButtonName } = props;
  return (
    <Button size="sm" backgroundColor="lime.500" color="lime.900">
      {ButtonName}
    </Button>
  );
};

export const SendButton = (props: SendButtonProps) => {
  const { onClick, ButtonName } = props;
  return (
    <Button
      onClick={onClick}
      size="sm"
      backgroundColor="lime.500"
      color="lime.900"
      width="50%"
    >
      {ButtonName}
    </Button>
  );
};

export const SendOkButton = (props: SendOkButtonProps) => {
  const { onClick, ButtonName } = props;
  return (
    <Button
      disabled
      size="sm"
      backgroundColor="gray.500"
      color="gray.900"
      width="50%"
    >
      {ButtonName}
    </Button>
  );
};

export const GetGpsButton = (props: GetGpsButtonProps) => {
  const { onClick, ButtonName } = props;
  return (
    <Button
      onClick={onClick}
      size="sm"
      backgroundColor="lime.500"
      color="lime.900"
      width="50%"
    >
      {ButtonName}
    </Button>
  );
};

export const RetryButton = (props: RetryButtonProps) => {
    const { onClick, ButtonName } = props;
    return (
      <Button
        onClick={onClick}
        size="sm"
        backgroundColor="blue.500"
        color="blue.900"
        width="50%"
      >
        {ButtonName}
      </Button>
    );
  };
  