import React, { useState } from "react";
import { CommitPage } from "./commit";
import { DonePage } from "./done";

export const HomePage: React.FC = () => {
  const [data1, setData1] = useState<number>(36.04);
  const [data2, setData2] = useState<number>(139.37);
  const [isDonePageVisible, setDonePageVisible] = useState<boolean>(false);

  const handleDataGenerated = (newData1: number, newData2: number) => {
    setData1(newData1);
    setData2(newData2);
    setDonePageVisible(true); // データが生成されたらDonePageを表示する
  };

  return (
    <div className="App">
      <img src="./icon.png" width="64px"/>
      <h1><b>GGTR GPS情報登録プログラム</b></h1>

      <div>
        <center>
          {isDonePageVisible ? (
            <DonePage data1={data1} data2={data2} />
          ) : (
            <CommitPage onDataGenerated={handleDataGenerated} />
          )}
          {/* {!isDonePageVisible && (
            <button onClick={() => setDonePageVisible(true)}>Show Done Page</button>
          )} */}
        </center>
      </div>
    </div>
  );
};
