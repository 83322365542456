import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';

interface MarkerPosition {
  lat: number;
  lng: number;
}

interface GoogleMapAreaProps {
  initialPosition: MarkerPosition;
}

export const GoogleMapArea: React.FC<GoogleMapAreaProps> = ({ initialPosition }) => {
  const [markerPosition, setMarkerPosition] = useState<MarkerPosition>(initialPosition);

  useEffect(() => {
    // 初期位置が変更されたら、マーカーの位置も更新する
    setMarkerPosition(initialPosition);
  }, [initialPosition]);

  const handleMarkerChange = (newPosition: MarkerPosition) => {
    setMarkerPosition(newPosition);
  };

  const handleApiLoaded = ({ map, maps }: { map: google.maps.Map, maps: typeof google.maps }) => {
    // マーカーを作成する前に、markerPositionが更新されるのを待つ
    if (markerPosition.lat !== null && markerPosition.lng !== null) {
      new maps.Marker({
        map,
        position: markerPosition,
      });
      map.setCenter(new maps.LatLng(markerPosition.lat, markerPosition.lng));
    }
    console.log('Marker Position:', markerPosition);
  };

  return (
      <div style={{ height: '240px', width: '92%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAAknsbJdWBMQK4V4Vty3NQ16lgQvA_FAc" }}
          // bootstrapURLKeys={{ key: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY }}
          defaultCenter={markerPosition}
          defaultZoom={7}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded({ map, maps })}
        />
      </div>
  );
}