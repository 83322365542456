import React, { useState, useEffect, useRef } from 'react';
import './App.css';

//確認画面
import { HomePage } from './page/home';
import { CommitPage } from './page/commit';
import { DonePage } from './page/done';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/commit" element={<CommitPage onDataGenerated={function (data1: any, data2: any): void {
          throw new Error('Function not implemented.');
        } } />} />
        <Route path="/done" element={<DonePage data1={0} data2={0}/>} />
      </Routes>
    </Router>
  );
}

export default App;